import Head from "next/head";

const Home: React.FC = () => {
  return (
    <div>
      <Head>
        <title>Landing pages</title>
        <meta name="description" content="Adway landing pages" />
        <link rel="icon" href="/favicon-32x32.png" />
      </Head>
    </div>
  );
};

export default Home;
